import { render, staticRenderFns } from "./hall.vue?vue&type=template&id=7da6aded&scoped=true"
import script from "./hall.vue?vue&type=script&lang=js"
export * from "./hall.vue?vue&type=script&lang=js"
import style0 from "./hall.vue?vue&type=style&index=0&id=7da6aded&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da6aded",
  null
  
)

export default component.exports